import {
  TableHeaders,
  CellContentType
} from '@/shared/components/table/models';

export const UserListHeaders: TableHeaders[] = [
  { key: 'number', label: 'NO', tdClass: 'min-w-50' },
  {
    key: 'site',
    label: `DIV`,
    sortable: true
  },
  {
    key: 'role',
    label: `Authority`,
    sortable: true
  },
  {
    key: 'id',
    label: `ID`
  },
  {
    key: 'firstName',
    label: `First Name`,
    sortable: true
  },
  {
    key: 'lastName',
    label: `Last Name`,
    sortable: true
  },
  {
    key: 'lastActivity',
    label: `Last Activity`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date,
      skipValidation: true
    }
  },
  ,
  {
    key: 'active',
    label: 'Status',
    content: { type: CellContentType.Switcher }
  },
  {
    key: 'edit',
    label: '',
    content: {
      type: CellContentType.Button,
      label: 'Edit'
    }
  },
  {
    key: 'reset',
    label: '',
    content: {
      type: CellContentType.Button,
      label: 'Reset'
    }
  },
  {
    key: 'delete',
    label: '',
    content: {
      type: CellContentType.Button,
      label: 'Delete'
    }
  }
];
